// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import feather from "feather-icons"
import 'fslightbox'

Turbo.session.drive = false;


document.addEventListener("DOMContentLoaded", () => {
    feather.replace()
    const menu_btn = document.querySelector('.mobile_button')
    const menu_close =  document.querySelector('.mobile_menu_close')
    const menu = document.querySelector(".mobile_menu")
    menu_btn.addEventListener("click", () => {
        menu.classList.add("open")
    })
    menu_close.addEventListener("click", () => {
        menu.classList.remove("open")
    })
    const contacts_btn = document.querySelector(".js-pop")
    if ( contacts_btn ) {
        contacts_btn.addEventListener("click",() => {
            const pop = document.querySelector('.pop')
            pop.classList.add("open")
        })
        const close = document.querySelector('.pop_close')
        close.addEventListener("click", () => {
            const pop = document.querySelector('.pop')
            pop.classList.remove("open")
        })
    }
})
